
















import Vue from "vue";
import _ from "lodash";

export default Vue.extend({
  name: "QCheckbox",
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    textKey: {
      type: String,
      required: false,
      default: "text"
    },
    valueKey: {
      type: String,
      required: false,
      default: "value"
    },
    min: {
      type: Number,
      required: false,
      default: 0
    },
    max: {
      type: Number,
      required: false,
      default: 1000
    }
  },
  data() {
    const cloneValue = _.cloneDeep(this.value) as string[];

    return {
      cloneValue
    };
  },
  watch: {
    value: {
      deep: true,
      handler(): void {
        this.cloneValue = _.cloneDeep(this.value);
      }
    }
  },
  computed: {
    isValid(): boolean {
      const optionIds = _.map(this.options, optionn => optionn.id);
      const selectCount =
        _.countBy(this.value, value => {
          return _.includes(optionIds, value);
        }).true || 0;

      return selectCount <= this.max && this.min <= selectCount;
    }
  },
  methods: {
    handleClick(key: string): void {
      const check = _.findIndex(this.$data.cloneValue, value => value === key);

      if (check === -1) {
        this.$emit("add", key);
      } else {
        this.$emit("remove", key);
      }
    },

    text(opt: any, key: string): string {
      const splittedKeys = key.split(".");
      let result = opt;

      for (const splittedKey of splittedKeys) {
        result = result[splittedKey] || "";
      }

      return result;
    },
    isChecked(key: string): string {
      const check = _.find(this.$data.cloneValue, value => value === key);

      if (check) {
        return "checked";
      }

      return null;
    },
    optionDisabled(option): boolean {
      let isChecked = this.isChecked(this.text(option, this.valueKey));
      // case aw checked then not disabled
      if (isChecked) {
        return false;
      }
      return option.disabled || false;
    }
  }
});
